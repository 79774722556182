<template>
    <!-- Navbar -->
    <div
        class="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
    >
        <ul class="navbar-nav flex-row align-items-center ms-auto">
            <!-- User -->
            <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                    class="nav-link dropdown-toggle hide-arrow"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                >
                    <div :class="'avatar avatar-' + dataLogin.status">
                        <img
                            :src="dataLogin.foto_profil"
                            alt
                            class="w-px-40 h-auto rounded-circle"
                        />
                    </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                        <a class="dropdown-item" href="/user/profile">
                            <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar avatar-online">
                                        <img
                                            :src="dataLogin.foto_profil"
                                            alt
                                            class="w-px-40 h-auto rounded-circle"
                                        />
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <span class="fw-semibold d-block">{{
                                        dataLogin.full_name
                                    }}</span>
                                    <small class="text-muted">{{
                                        dataLogin.jabatan
                                    }}</small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="dropdown-divider"></div>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/user/profile">
                            <i class="bx bx-user me-2"></i>
                            <span class="align-middle">My Profile</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/user/setting">
                            <i class="bx bx-cog me-2"></i>
                            <span class="align-middle">Settings</span>
                        </a>
                    </li>
                    <li>
                        <div class="dropdown-divider"></div>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/logout">
                            <i class="bx bx-power-off me-2"></i>
                            <span class="align-middle">Log Out</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!--/ User -->
        </ul>
    </div>
    <!-- / Navbar -->
</template>

<script>
export default {
    mounted() {
        if (this.data_logins != "")
            this.dataLogin = JSON.parse(this.data_logins);
        // console.log('Component mounted.')
    },
    data() {
        return {
            url: "//sarpras.abp/",
            dataLogin: {},
        };
    },
    props: ["data_logins"],
};
</script>
