<template>
    <h4 class="mb-2">Pendaftaran Akun Sistem Sarpras</h4>
    <p class="mb-4">Daftarkan akun anda untuk dapat masuk!</p>
    <form id="formAuthentication" class="mb-3" method="POST" autocomplete="off">
        <input type="hidden" name="_token" id="_token" v-model="csrfTokens" />
        <input type="hidden" name="level_user" id="level_user" value="user" />
        <div class="mb-3">
            <label for="fullName" class="form-label">Nama Lengkap</label>
            <input
                type="text"
                class="form-control"
                id="fullName"
                name="fullName"
                placeholder="Masukkan nama lengkap anda"
                autofocus
            />
        </div>
        <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input
                type="text"
                class="form-control"
                id="username"
                name="username"
                placeholder="Masukkan username anda"
                autofocus
            />
        </div>
        <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
                type="text"
                class="form-control"
                id="email"
                name="email"
                placeholder="Masukkan email anda"
            />
        </div>
        <div class="mb-3 form-password-toggle">
            <label class="form-label" for="password">Kata Sandi</label>
            <div class="input-group input-group-merge">
                <input
                    type="password"
                    id="password"
                    class="form-control"
                    name="password"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                />
                <span class="input-group-text cursor-pointer"
                    ><i class="bx bx-hide"></i
                ></span>
            </div>
        </div>

        <div class="mb-3">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="terms-conditions"
                    name="terms"
                />
                <label class="form-check-label" for="terms-conditions">
                    Saya Setuju dengan
                    <a href="javascript:void(0);"
                        >kebijakan & ketentuan privasi</a
                    >
                </label>
            </div>
        </div>
        <button class="btn btn-primary d-grid w-100">Daftar</button>
    </form>
    <p class="text-center">
        <span>Sudah punya akun?</span>
        <a href="/auth/login">
            <span>Masuk saja</span>
        </a>
    </p>
</template>

<script>
export default {
    mounted() {
        if (this.tokens != null) {
            this.csrfTokens = this.tokens;
        }
    },
    data() {
        return {
            csrfTokens: "",
        };
    },
    props: ["tokens"],
};
</script>
