import { createApp } from "vue";
//LAYOUT
import NavBar from "./layout/nav.vue";
import RowOne from "./home/rowone.vue";
import RowTwo from "./home/rowtwo.vue";
import MyToast from "./layout/ts_bs.vue";
//LAYOUT
//SARANA
import MenuSarana from "./sarana/menu.vue";
import Tablesarana from "./sarana/tablesarana.vue";
import FormSarana from "./sarana/form/lv.vue";
//SARANA
//P2H
import TableP2H from "./sarana/tablep2h.vue";
//P2H
//MASTER P2H
import TableMasterP2H from "./sarana/tablemasterp2h.vue";
import FormMasterP2H from "./sarana/form/masterp2h.vue";
import FormMasterItemP2H from "./sarana/form/masteritemp2h.vue";
import TableMasterItemP2H from "./sarana/tablemasteritemp2h.vue";
//MASTER P2H
//AUTH
import LoginForm from "./auth/login.vue";
import RegisterForm from "./auth/register.vue";
//AUTH
const app = createApp({});

app.component("navbar", NavBar)
    .component("content-row-one", RowOne)
    .component("content-row-two", RowTwo)
    .component("my-toast", MyToast);

app.component("form-sarana", FormSarana)
    .component("table-sarana", Tablesarana)
    .component("menu-sarana", MenuSarana);

app.component("p2h-table", TableP2H)
    .component("master-p2h-form", FormMasterP2H)
    .component("master-p2h-table", TableMasterP2H)
    .component("master-p2h-item-form", FormMasterItemP2H)
    .component("master-p2h-item-table", TableMasterItemP2H);

app.component("login-form", LoginForm).component("register-form", RegisterForm);

app.mount("#app");
