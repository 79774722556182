<template>
    <div>
        <!-- Tabel Sarana -->
        <div class="card">
            <div class="card-header">
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-6 order-1 order-lg-1">
                            <h5
                                style="
                                    vertical-align: middle !important;
                                    float: left !important;
                                "
                            >
                                Pengecekan dan Pemeriksaan Harian (P2h)
                            </h5>
                        </div>
                        <div class="col-6 col-lg-6 order-2 order-lg-2">
                            <div class="col-12 col-lg-12">
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-sm btn-primary me-2"
                                        type="button"
                                        @click="inputFocusEvent"
                                    >
                                        <span class="tf-icons bx bx-plus"></span
                                        >&nbsp; Tambah Sarana
                                    </button>
                                    <button
                                        ref="updateBtn"
                                        id="showToastPlacement"
                                        class="btn btn-sm btn-warning"
                                        type="button"
                                        @click="getVehicle(halaman)"
                                    >
                                        <span
                                            class="tf-icons bx bx-refresh"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive text-nowrap">
                <table class="table table-hover">
                    <caption class="ms-4" v-if="vehicles.total > 0">
                        <span
                            >Showing
                            {{ vehicles.from }}
                            to
                            {{ vehicles.to }}
                            of
                            {{ vehicles.total }}</span
                        >
                        <span>Pengecekan dan Pemeriksaan Harian (P2h)</span>
                    </caption>
                    <thead>
                        <tr>
                            <th colspan="2">AKSI</th>
                            <th>NOMOR</th>
                            <th>DRIVER / PENGEMUDI</th>
                            <th>KODE UNIT</th>
                            <th>NOPOL / LAMB</th>
                            <th>HARI / TANGGAL</th>
                            <th>SHIFT</th>
                            <th>HM / KM AWAL</th>
                            <th>HM / KM AKHIR</th>
                            <th>DEPARTEMEN</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th colspan="2">AKSI</th>
                            <th>NOMOR</th>
                            <th>DRIVER / PENGEMUDI</th>
                            <th>KODE UNIT</th>
                            <th>NOPOL / LAMB</th>
                            <th>HARI / TANGGAL</th>
                            <th>SHIFT</th>
                            <th>HM / KM AWAL</th>
                            <th>HM / KM AKHIR</th>
                            <th>DEPARTEMEN</th>
                        </tr>
                    </tfoot>
                    <tbody class="table-border-bottom-0">
                        <tr v-for="(a, b) in vehicles.data">
                            <td>
                                <div class="dropdown">
                                    <button
                                        type="button"
                                        class="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                    >
                                        <i
                                            class="bx bx-dots-vertical-rounded"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <button
                                            class="dropdown-item"
                                            @click="editSarana(a.idVehicle)"
                                        >
                                            <i class="bx bx-edit-alt me-1"></i>
                                            Edit
                                        </button>
                                        <button
                                            class="dropdown-item"
                                            @click="del(a.idVehicle)"
                                        >
                                            <i class="bx bx-trash me-1"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: center">
                                <span
                                    :class="
                                        a.status
                                            ? 'badge me-1 bg-label-success'
                                            : 'badge me-1 bg-label-danger'
                                    "
                                    >{{
                                        a.status == 1
                                            ? "DIPERIKSA"
                                            : "BELUM DI PERIKSA"
                                    }}</span
                                >
                            </td>
                            <td>
                                <i
                                    class="fab fa-angular fa-lg text-danger me-3"
                                ></i>
                                <strong>{{ vehicles.from + b }}</strong>
                            </td>
                            <td>{{ a.noLambung }}</td>
                            <td>
                                <ul
                                    v-if="a.pic != null"
                                    class="list-unstyled users-list m-0 avatar-group d-flex align-items-center"
                                >
                                    <li
                                        data-bs-toggle="tooltip"
                                        data-popup="tooltip-custom"
                                        data-bs-placement="top"
                                        class="avatar avatar-xs pull-up"
                                        :title="a.pic"
                                    >
                                        <img
                                            src="/img/avatars/5.png"
                                            alt="Avatar"
                                            class="rounded-circle"
                                        />
                                    </li>
                                </ul>
                            </td>
                            <td>{{ a.noPol }}</td>
                            <td>{{ a.merek }}</td>
                            <td>{{ a.type }}</td>
                            <td>{{ a.detail.pemilik }}</td>
                            <td>{{ a.kontrak }}</td>
                        </tr>
                        <tr v-if="vehicles.total == 0">
                            <td>Tidak Ada Data</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <Bootstrap5Pagination
                    :data="vehicles"
                    @pagination-change-page="getVehicle"
                />
            </div>
        </div>
        <!--/ Hoverable Table rows -->
        <!-- Form Sarana -->
        <form-sarana
            @tabelSaranaEmit="doEmits"
            ref="focusRef"
            :tokens="tokens"
        ></form-sarana>
    </div>
</template>

<script>
import axios from "axios";
import { Bootstrap5Pagination } from "laravel-vue-pagination";
import FormSarana from "./form/lv.vue";
import { ref } from "vue";
const focusRef = ref(null);

export default {
    components: {
        Bootstrap5Pagination,
        FormSarana,
    },
    mounted() {
        this.csrfTokens = this.tokens;
        this.getVehicle(this.halaman);
        var thisFocus = this;
        var myOffcanvas = document.getElementById("offcanvasBoth");

        myOffcanvas.addEventListener("shown.bs.offcanvas", () => {
            thisFocus.$refs.focusRef.focusInputs();
        });
    },
    data() {
        return {
            vehicles: {},
            halaman: 1,
            csrfTokens: "",
        };
    },
    props: ["tokens"],
    methods: {
        toast() {
            $("#myToast").toast("show");
        },
        getVehicle(page) {
            if (page != undefined) {
                this.halaman = page;
            }
            axios
                .get("/sarana/lv/create?page=" + this.halaman)
                .then((response) => {
                    var vehicle = response.data;
                    if (vehicle != null) {
                        this.vehicles = vehicle;
                    }
                });
        },
        del(idVehicle) {
            axios.delete("/sarana/lv/" + idVehicle).then((response) => {
                if (response != null) {
                    this.vehicles = {};
                    this.getVehicle(this.halaman);
                }
            });
        },
        doEmits() {
            this.getVehicle(this.halaman);
        },
        inputFocusEvent() {
            var thisFocus = this;
            var myOffcanvas = document.getElementById("offcanvasBoth");

            console.log("new form event");
            thisFocus.$refs.focusRef.initDatas();
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

            bsOffcanvas.toggle();
        },
        editSarana(id) {
            var thisFocus = this;
            var myOffcanvas = document.getElementById("offcanvasBoth");
            thisFocus.$refs.focusRef.getByIds(id);
            console.log("edit form event");

            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.toggle();
        },
    },
};
</script>
