<template>
    <div class="col-lg-12 col-md-12 col-12 mb-12 order-1">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-6 mb-4 order-1">
                <a href="/sarana/lv" class="card">
                    <div class="card-body">
                        <div
                            class="card-title d-flex align-items-start justify-content-between"
                        >
                            <div class="avatar flex-shrink-0">
                                <span class="badge bg-label-success">
                                    <i class="bx bx-car"></i>
                                </span>
                            </div>
                            <h4 class="card-title mb-2">Light Vehicle (LV)</h4>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-6 col-md-12 col-6 mb-4 order-2">
                <a href="/sarana/p2h" class="card">
                    <div class="card-body">
                        <div
                            class="card-title d-flex align-items-start justify-content-between"
                        >
                            <div class="avatar flex-shrink-0">
                                <span class="badge bg-label-dark">
                                    <i class="bx bx-building"></i>
                                </span>
                            </div>
                            <h4 class="card-title mb-2">
                                Pengecekan dan Pemeriksaan Harian (P2H)
                            </h4>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-6 col-md-12 col-6 mb-4 order-3">
                <a href="/sarana/service" class="card">
                    <div class="card-body">
                        <div
                            class="card-title d-flex align-items-start justify-content-between"
                        >
                            <div class="avatar flex-shrink-0">
                                <span class="badge bg-label-success">
                                    <i class="bx bx-table"></i>
                                </span>
                            </div>
                            <h4 class="card-title mb-2">Servis</h4>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-6 col-md-12 col-6 mb-4 order-4">
                <a href="/sarana/ba" class="card">
                    <div class="card-body">
                        <div
                            class="card-title d-flex align-items-start justify-content-between"
                        >
                            <div class="avatar flex-shrink-0">
                                <span class="badge bg-label-danger">
                                    <i class="bx bx-wrench"></i>
                                </span>
                            </div>
                            <h4 class="card-title mb-2">Berita Acara</h4>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-6 col-md-12 col-6 mb-4 order-4">
                <a href="/sarana/ceklog" class="card">
                    <div class="card-body">
                        <div
                            class="card-title d-flex align-items-start justify-content-between"
                        >
                            <div class="avatar flex-shrink-0">
                                <span class="badge bg-label-danger">
                                    <i class="bx bx-wrench"></i>
                                </span>
                            </div>
                            <h4 class="card-title mb-2">Cek Log Sarana</h4>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {},
};
</script>
