<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasBoth"
        aria-labelledby="offcanvasAddUserLabel"
    >
        <div class="offcanvas-header border-bottom">
            <h5 id="offcanvasAddUserLabel" class="offcanvas-title">
                FORM MASTER P2H
            </h5>
            <button
                type="button"
                id="dismisBtn"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body mx-0 flex-grow-0 p-6 h-100">
            <div class="col-xl">
                <form
                    @submit.prevent="send"
                    autocomplete="off"
                    autocapitalize="on"
                >
                    <div class="mb-3">
                        <label class="form-label" for="itemList"
                            >ITEM LIST
                        </label>
                        <input
                            type="hidden"
                            name="_token"
                            v-model="csrfTokens"
                        />
                        <input
                            type="text"
                            class="form-control"
                            id="itemList"
                            ref="itemList"
                            name="itemList"
                            v-model="p2h.itemList"
                            placeholder="Item List"
                            required="required"
                        />
                    </div>
                    <div class="mb-3 border-bottom">
                        <label class="form-label" for="status">STATUS</label>
                        <span class="form-control-plaintext">
                            <div class="form-check form-switch mb-2">
                                <input
                                    @change="chgStatus"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="status"
                                    v-model="chkStatus"
                                />
                                <label class="form-check-label" for="status"
                                    >Aktif</label
                                >
                            </div>
                        </span>
                    </div>
                    <button
                        type="submit"
                        class="btn btn-primary me-3 data-submit"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        class="btn btn-outline-danger"
                        data-bs-dismiss="offcanvas"
                    >
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    mounted() {
        this.initDatas();
    },
    data() {
        return {
            urlAction: "/",
            csrfTokens: "",
            p2h: {},
            eP2h: {},
            chkStatus: true,
            idP2hList: null,
        };
    },
    props: ["tokens", "url_action"],
    methods: {
        send() {
            this.idP2hList != null
                ? this.updateAPI(this.idP2hList)
                : this.save();
        },
        save() {
            axios.post("/sarana/masterp2h", this.p2h).then((response) => {
                var data = response.data;
                if (data.action) {
                    $("#myToast").toast("show");
                    $("#dismisBtn").click();
                    this.initDatas();
                    this.updateData();
                }
            });
        },
        updateAPI(id) {
            axios.put("/sarana/masterp2h/" + id, this.p2h).then((response) => {
                var data = response.data;
                if (data.action) {
                    $("#myToast").toast("show");
                    $("#dismisBtn").click();
                    this.initDatas();
                    this.updateData();
                }
            });
        },
        getByIds(id) {
            this.initDatas();
            this.idP2hList = id;
            axios
                .get("/sarana/masterp2h/" + this.idP2hList + "/edit")
                .then((response) => {
                    var v = response.data;
                    if (v != null) {
                        var s = {};
                        s.itemList = v.itemList;
                        s.statusList = v.statusList;
                        this.p2h = s;
                    }
                });
        },

        updateData() {
            this.$emit("tabelP2hEmit");
        },
        focusInputs() {
            this.$refs.itemList.focus();
        },

        chgStatus() {
            this.chkStatus != this.chkStatus;
            if (this.chkStatus) {
                this.p2h.status = 1;
            } else {
                this.p2h.status = 0;
            }
        },
        initDatas() {
            this.p2h = {};
            if (this.url_action != null) {
                this.urlAction = this.url_action;
            }
            if (this.tokens != null) {
                this.csrfTokens = this.tokens;
            }
            this.p2h.statusList = 1;
        },
    },
    expose: ["initDatas", "getByIds", "focusInputs"],
};
</script>
