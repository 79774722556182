<template>

<div class="col-lg-12 col-md-12 col-12 mb-12 order-1">
    <div class="row">

      <div class="col-lg-3 col-md-12 col-6 mb-4 order-1">
            <div class="card">
                <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                        <span class="badge bg-label-success">
                            <i class="bx bx-car"></i>
                        </span>
                        </div>
                        <div class="dropdown">
                        <button
                            class="btn p-0"
                            type="button"
                            id="cardOpt3"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                            <a class="dropdown-item" href="javascript:void(0);">View More</a>
                            <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                        </div>
                        </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Light Vehicle (LV)</span>
                    <h3 class="card-title mb-2">14</h3>
<small class="text-warning fw-semibold">100%</small>
                </div>
            </div>
        </div>
        
        <div class="col-lg-3 col-md-12 col-6 mb-4 order-2">
            <div class="card">
                <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                        <span class="badge bg-label-dark">
                            <i class="bx bx-building"></i>
                        </span>
                        </div>
                        <div class="dropdown">
                        <button
                            class="btn p-0"
                            type="button"
                            id="cardOpt3"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                            <a class="dropdown-item" href="javascript:void(0);">View More</a>
                            <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                        </div>
                        </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Bangunan</span>
                    <h3 class="card-title mb-2">14</h3>
<small class="text-success fw-semibold">100</small>
                </div>
            </div>
        </div>
        
        <div class="col-lg-3 col-md-12 col-6 mb-4 order-3">
            <div class="card">
                <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                        <span class="badge bg-label-success">
                            <i class="bx bx-table"></i>
                        </span>
                        </div>
                        <div class="dropdown">
                        <button
                            class="btn p-0"
                            type="button"
                            id="cardOpt3"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                            <a class="dropdown-item" href="javascript:void(0);">View More</a>
                            <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                        </div>
                        </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Pekerjaan</span>
                    <h3 class="card-title mb-2">14</h3>
<small class="text-danger fw-semibold">100%</small>
                </div>
            </div>
        </div>
        
        <div class="col-lg-3 col-md-12 col-6 mb-4 order-4">
            <div class="card">
                <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                        <div class="avatar flex-shrink-0">
                        <span class="badge bg-label-danger">
                            <i class="bx bx-wrench"></i>
                        </span>
                        </div>
                        <div class="dropdown">
                        <button
                            class="btn p-0"
                            type="button"
                            id="cardOpt3"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                            <a class="dropdown-item" href="javascript:void(0);">View More</a>
                            <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                        </div>
                        </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">SPIP</span>
                    <h3 class="card-title mb-2">14</h3>
<small class="text-success fw-semibold">100%</small>
                </div>
            </div>
        </div>
        
    </div>
</div>

</template>

<script>
    export default {
        mounted() {
        }
    }
</script>