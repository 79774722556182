<template>
    <div
        id="myToast"
        :class="
            'bs-toast toast toast-placement-ex m-3 bottom-0 end-0 ' +
            toasts.colors
        "
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
    >
        <div class="toast-header">
            <i :class="'bx me-2 ' + toasts.icon"></i>
            <div class="me-auto fw-semibold">{{ toasts.title }}</div>
            <small>{{ toasts.time }}</small>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
            ></button>
        </div>
        <div class="toast-body">{{ toasts.message }}</div>
    </div>
</template>

<script>
export default {
    mounted() {
        if (this.toast_message != null) {
            this.toasts = this.toast_message;
        }
        this.toasts.icon = "bx-bell";
        this.toasts.colors = "bg-success";
    },
    data() {
        return {
            toasts: {},
        };
    },
    props: ["toast_message"],
};
</script>
