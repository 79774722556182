<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasBoth"
        aria-labelledby="offcanvasAddUserLabel"
    >
        <div class="offcanvas-header border-bottom">
            <h5 id="offcanvasAddUserLabel" class="offcanvas-title">
                FORM SARANA
            </h5>
            <button
                type="button"
                id="dismisBtn"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body mx-0 flex-grow-0 p-6 h-100">
            <div class="col-xl">
                <form
                    @submit.prevent="send"
                    autocomplete="off"
                    autocapitalize="on"
                >
                    <div class="mb-3">
                        <label class="form-label" for="nomorLambung"
                            >NOMOR LAMBUNG
                        </label>
                        <input
                            type="hidden"
                            name="_token"
                            v-model="csrfTokens"
                        />
                        <input
                            type="text"
                            class="form-control"
                            id="nomorLambung"
                            ref="noLambung"
                            name="noLambung"
                            v-model="sarana.noLambung"
                            placeholder="NOMOR LAMBUNG"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="noPol"
                            >NOMOR POLISI</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="noPol"
                            v-model="sarana.noPol"
                            placeholder="NOMOR POLISI"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="merek">MEREK</label>
                        <input
                            type="text"
                            class="form-control"
                            id="merek"
                            v-model="sarana.merek"
                            placeholder="MEREK"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="type">TYPE</label>
                        <input
                            type="text"
                            class="form-control"
                            id="type"
                            v-model="sarana.type"
                            placeholder="TYPE"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="noMesin"
                            >NOMOR MESIN</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="noMesin"
                            v-model="sarana.noMesin"
                            placeholder="NOMOR MESIN"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="warna">WARNA</label>
                        <input
                            type="text"
                            class="form-control"
                            id="warna"
                            v-model="sarana.warna"
                            placeholder="WARNA"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="thnPembuatan"
                            >TAHUN PEMBUATAN</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="thnPembuatan"
                            v-model="sarana.thnPembuatan"
                            placeholder="TAHUN PEMBUATAN"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="pajak"
                            >MASA BERLAKU PAJAK</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="pajak"
                            v-model="sarana.pajak"
                            placeholder="MASA BERLAKU PAJAK"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="kir"
                            >MASA BERAKHIR KIR</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="kir"
                            v-model="sarana.kir"
                            placeholder="MASA BERAKHIR KIR"
                            required="required"
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="stnk"
                            >MASA BERLAKU STNK</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="stnk"
                            v-model="sarana.stnk"
                            placeholder="MASA BERLAKU STNK"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="pemilik"
                            >NAMA PEMILIK</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="pemilik"
                            v-model="sarana.pemilik"
                            placeholder="NAMA PEMILIK"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="serahTerima"
                            >TANGGAL SERAH TERIMA</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="serahTerima"
                            v-model="sarana.serahTerima"
                            placeholder="TANGGAL SERAH TERIMA"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="kontrak"
                            >TANGGAL KONTRAK BERAKHIR</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="kontrak"
                            v-model="sarana.kontrak"
                            placeholder="TANGGAL KONTRAK BERAKHIR"
                            required="required"
                        />
                    </div>
                    <!-- <div class="mb-3"> -->
                    <!-- <label class="form-label" for="sewa">BIAYA SEWA</label> -->
                    <input
                        type="hidden"
                        class="form-control"
                        id="sewa"
                        v-model="sarana.sewa"
                        placeholder="BIAYA SEWA"
                    />
                    <!-- </div> -->
                    <div class="mb-3">
                        <label class="form-label" for="pic"
                            >PERSON IN CHARGE (PIC)</label
                        >
                        <input
                            type="text"
                            class="form-control"
                            id="pic"
                            v-model="sarana.pic"
                            placeholder="PERSON IN CHARGE (PIC)"
                            required="required"
                        />
                    </div>
                    <div class="mb-3 border-bottom">
                        <label class="form-label" for="status">STATUS</label>
                        <span class="form-control-plaintext">
                            <div class="form-check form-switch mb-2">
                                <input
                                    @change="chgStatus"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="status"
                                    v-model="chkStatus"
                                />
                                <label class="form-check-label" for="status"
                                    >Aktif</label
                                >
                            </div>
                        </span>
                    </div>
                    <button
                        type="submit"
                        class="btn btn-primary me-3 data-submit"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        class="btn btn-outline-danger"
                        data-bs-dismiss="offcanvas"
                    >
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    mounted() {
        this.initDatas();
    },
    data() {
        return {
            urlAction: "/",
            csrfTokens: "",
            sarana: {},
            eSarana: {},
            chkStatus: true,
            idVehicles: null,
        };
    },
    props: ["tokens", "url_action"],
    methods: {
        send() {
            this.idVehicles != null
                ? this.updateAPI(this.idVehicles)
                : this.save();
        },
        save() {
            axios.post("/sarana/lv", this.sarana).then((response) => {
                var data = response.data;
                if (data.success) {
                    $("#myToast").toast("show");
                    $("#dismisBtn").click();
                    this.initDatas();
                    this.updateData();
                }
            });
        },
        updateAPI(id) {
            axios.put("/sarana/lv/" + id, this.sarana).then((response) => {
                var data = response.data;
                if (data.success) {
                    $("#myToast").toast("show");
                    $("#dismisBtn").click();
                    this.initDatas();
                    this.updateData();
                }
            });
        },
        getByIds(id) {
            this.initDatas();
            this.idVehicles = id;
            axios
                .get("/sarana/lv/" + this.idVehicles + "/edit")
                .then((response) => {
                    var v = response.data;
                    if (v != null) {
                        var s = {};
                        s.noLambung = v.noLambung;
                        s.noPol = v.noPol;
                        s.pic = v.pic;
                        s.merek = v.merek;
                        s.type = v.type;
                        s.kontrak = v.kontrak;
                        s.sewa = v.sewa;
                        s.status = v.status;
                        s.uidVehicle = v.uidVehicle;
                        s.slug = v.slug;

                        s.noMesin = v.detail.noMesin;
                        s.warna = v.detail.warna;
                        s.thnPembuatan = v.detail.thnPembuatan;
                        s.pajak = v.detail.pajak;
                        s.kir = v.detail.kir;
                        s.stnk = v.detail.stnk;
                        s.pemilik = v.detail.pemilik;
                        s.serahTerima = v.detail.serahTerima;
                        this.sarana = s;
                        console.log("pic" + s.pic);
                    }
                });
        },
        getUid() {
            axios.get("/get/uids").then((response) => {
                this.sarana.uidVehicle = response.data;
            });
        },

        updateData() {
            this.$emit("tabelSaranaEmit");
        },
        focusInputs() {
            this.$refs.noLambung.focus();
        },

        chgStatus() {
            this.chkStatus != this.chkStatus;
            if (this.chkStatus) {
                this.sarana.status = 1;
            } else {
                this.sarana.status = 0;
            }
        },
        initDatas() {
            this.sarana = {};
            if (this.url_action != null) {
                this.urlAction = this.url_action;
            }
            if (this.tokens != null) {
                this.csrfTokens = this.tokens;
            }
            this.sarana.status = 1;
            this.getUid();
        },
    },
    expose: ["initDatas", "getByIds", "focusInputs"],
};
</script>
