<template>
    <h4 class="mb-2">Selamat Datang Di Sarpras! 👋</h4>
    <p class="mb-4">Silahkan Masuk Terlebih Dahulu</p>

    <form id="formAuthentication" @submit.prevent="doLogin" class="mb-3">
        <input type="hidden" name="_token" id="_token" v-model="csrfTokens" />
        <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input
                type="text"
                class="form-control"
                ref="username"
                id="username"
                name="username"
                v-model="dataLogin.username"
                placeholder="Masukkan username anda"
                required="required"
                tabindex="1"
            />
        </div>
        <div class="mb-3 form-password-toggle">
            <div class="d-flex justify-content-between">
                <label class="form-label" for="password">Kata Sandi</label>
                <a href="/auth/forgot/password'" tabindex="5">
                    <small>Lupa Kata Sandi?</small>
                </a>
            </div>
            <div class="input-group input-group-merge">
                <input
                    type="password"
                    id="password"
                    class="form-control"
                    v-model="dataLogin.password"
                    name="password"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                    required="required"
                    tabindex="2"
                />
                <span class="input-group-text cursor-pointer"
                    ><i class="bx bx-hide"></i
                ></span>
            </div>
        </div>
        <div class="mb-3">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="ingat_saya"
                    :checked="dataLogin.remember"
                    @change="doCheck"
                    tabindex="3"
                />
                <label class="form-check-label" for="ingat_saya">
                    Ingat Saya
                </label>
            </div>
        </div>
        <div class="mb-3">
            <button
                class="btn btn-primary d-grid w-100"
                type="submit"
                tabindex="4"
            >
                Masuk
            </button>
        </div>
    </form>

    <p class="text-center">
        <span>Pengguna Baru?</span>
        <a href="/auth/register">
            <span>Buat Akun</span>
        </a>
    </p>
    <my-toast :toast_message="toasts"></my-toast>
</template>
<script>
import axios from "axios";
import MyToast from "../layout/ts_bs.vue";
export default {
    mounted() {
        if (this.tokens != null) {
            this.csrfTokens = this.tokens;
        }
        this.dataLogin.remember = false;
        this.dataLogin.referer = this.referer;
        this.doFocus();
    },
    data() {
        return {
            csrfTokens: "",
            dataLogin: {},
            toasts: {},
        };
    },
    props: ["tokens", "referer"],
    methods: {
        doLogin() {
            axios.post("/auth/login", this.dataLogin).then((response) => {
                var m = response.data;
                if (m != null) {
                    this.initForms();
                    this.doToast({
                        title: m.action ? "Berhasil!" : "Gagal!",
                        message: m.message,
                        time: "Sekarang!",
                        colors: m.action ? "success" : "danger",
                    });
                    if (m.action) {
                        setTimeout(() => {
                            window.location = m.referer;
                        }, 1000);
                    }
                }
            });
        },
        doCheck() {
            this.dataLogin.remember = !this.dataLogin.remember;
        },
        doToast(m) {
            $("#dismisBtn").click();

            $(window).ready(function () {
                var myToastEl = document.getElementById("myToast");
                var runToast = new bootstrap.Toast(myToastEl);
                runToast.show();
            });

            this.toasts.title = m.title;
            this.toasts.message = m.message;
            this.toasts.time = m.time;
            this.toasts.icon = "bx-user";
            this.toasts.colors = "bg-" + m.colors;

            console.log("Toasted");
        },
        doFocus() {
            this.$refs.username.focus();
            console.log("focus");
        },
        initForms() {
            this.dataLogin.username = "";
            this.dataLogin.password = "";
            this.doFocus();
        },
    },
};
</script>
