<template>
    <div class="col-12 col-lg-12 order-2 order-md-2 order-lg-2 mb-4">
        <!-- Tabel Sarana -->
        <div class="card">
            <div class="card-header">
                <div class="col-12 col-lg-12">
                    <div class="row">
                        <div class="col-6 col-lg-6 order-1 order-lg-1">
                            <h5
                                style="
                                    vertical-align: middle !important;
                                    float: left !important;
                                "
                            >
                                Master List Item (P2h)
                            </h5>
                        </div>
                        <div class="col-6 col-lg-6 order-2 order-lg-2">
                            <div class="col-12 col-lg-12">
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-sm btn-primary me-2"
                                        type="button"
                                        @click="inputFocusEvent"
                                    >
                                        <span class="tf-icons bx bx-plus"></span
                                        >&nbsp; Tambah Item
                                    </button>
                                    <button
                                        ref="updateBtn"
                                        id="showToastPlacement"
                                        class="btn btn-sm btn-warning"
                                        type="button"
                                        @click="getP2hItem(halaman)"
                                    >
                                        <span
                                            class="tf-icons bx bx-refresh"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive text-nowrap">
                <table class="table table-hover">
                    <caption class="ms-4" v-if="p2hItem.total > 0">
                        <span
                            >Showing
                            {{ p2hItem.from }}
                            to
                            {{ p2hItem.to }}
                            of
                            {{ p2hItem.total }}&nbsp;
                        </span>
                        <span> List Master Item (P2h)</span>
                    </caption>
                    <thead>
                        <tr>
                            <th style="width: 10%">NOMOR</th>
                            <th>ITEM</th>
                            <th>SUB</th>
                            <th
                                colspan="2"
                                style="width: 20%; text-align: center"
                            >
                                AKSI
                            </th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>NOMOR</th>
                            <th>ITEM</th>
                            <th>SUB</th>
                            <th colspan="2" style="text-align: center">AKSI</th>
                        </tr>
                    </tfoot>
                    <tbody class="table-border-bottom-0">
                        <tr v-for="(a, b) in p2hItem.data">
                            <td>
                                <i
                                    class="fab fa-angular fa-lg text-danger me-3"
                                ></i>
                                <strong>{{ p2hItem.from + b }}</strong>
                            </td>
                            <td>{{ a.itemList }}</td>

                            <td style="text-align: center">
                                <span
                                    :class="
                                        a.statusList
                                            ? 'badge me-1 bg-label-success'
                                            : 'badge me-1 bg-label-danger'
                                    "
                                    >{{
                                        a.statusList == 1
                                            ? "Active"
                                            : "Non-Active"
                                    }}</span
                                >
                            </td>
                            <td style="text-align: center">
                                <div class="dropdown">
                                    <button
                                        type="button"
                                        class="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                    >
                                        <i
                                            class="bx bx-dots-vertical-rounded"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <button
                                            class="dropdown-item"
                                            @click="editItem(a.idP2hList)"
                                        >
                                            <i class="bx bx-edit-alt me-1"></i>
                                            Edit
                                        </button>
                                        <button
                                            class="dropdown-item"
                                            @click="delItem(a.idP2hList)"
                                        >
                                            <i class="bx bx-trash me-1"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="p2hItem.total == 0">
                            <td>Tidak Ada Data</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <Bootstrap5Pagination
                    :data="p2hItem"
                    @pagination-change-page="getP2hItem"
                />
            </div>
        </div>
        <!--/ Hoverable Table rows -->
        <!-- Form Sarana -->
        <master-p2h-item-form
            @tabelP2hItemEmit="doEmitsItem"
            ref="focusRef"
            :tokens="tokens"
        ></master-p2h-item-form>
    </div>
</template>

<script>
import axios from "axios";
import { Bootstrap5Pagination } from "laravel-vue-pagination";
import MasterItemP2hForm from "./form/masteritemp2h.vue";
import { ref } from "vue";
const focusRef = ref(null);

export default {
    components: {
        Bootstrap5Pagination,
        MasterItemP2hForm,
    },
    mounted() {
        this.csrfTokens = this.tokens;
        this.getP2hItem(this.halaman);
        var thisFocus = this;
        var myOffcanvas = document.getElementById("offcanvasBoth");

        myOffcanvas.addEventListener("shown.bs.offcanvas", () => {
            thisFocus.$refs.focusRef.focusInputs();
        });
    },
    data() {
        return {
            p2hItem: {},
            halaman: 1,
            csrfTokens: "",
        };
    },
    props: ["tokens"],
    methods: {
        toast() {
            $("#myToast").toast("show");
        },
        getP2hItem(page) {
            if (page != undefined) {
                this.halaman = page;
            }
            axios
                .get("/sarana/masteritemp2h/create?page=" + this.halaman)
                .then((response) => {
                    var p2hItem = response.data;
                    if (p2hItem != null) {
                        this.p2hItem = p2hItem;
                    }
                });
        },
        delItem(idP2hItem) {
            axios
                .delete("/sarana/masteritemp2h/" + idP2hItem)
                .then((response) => {
                    if (response != null) {
                        this.p2hItem = {};
                        this.getP2hList(this.halaman);
                    }
                });
        },
        doEmitsItem() {
            this.getP2hItem(this.halaman);
        },
        inputFocusEvent() {
            var thisFocus = this;
            var masterItemP2hCanvas = document.getElementById(
                "masterItemP2hCanvas"
            );

            console.log("new form event");
            thisFocus.$refs.focusRef.getP2hList();
            var bsOffcanvas = new bootstrap.Offcanvas(masterItemP2hCanvas);

            bsOffcanvas.toggle();
        },
        editItem(id) {
            var thisFocus = this;
            var masterItemP2hCanvas = document.getElementById(
                "masterItemP2hCanvas"
            );
            thisFocus.$refs.focusRef.getByIds(id);
            console.log("edit form event");

            var bsOffcanvas = new bootstrap.Offcanvas(masterItemP2hCanvas);
            bsOffcanvas.toggle();
        },
    },
};
</script>
