<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="masterItemP2hCanvas"
        aria-labelledby="offcanvasAddUserLabel"
    >
        <div class="offcanvas-header border-bottom">
            <h5 id="offcanvasAddUserLabel" class="offcanvas-title">
                FORM ITEM P2H
            </h5>
            <button
                type="button"
                id="dismisBtnItem"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body mx-0 flex-grow-0 p-6 h-100">
            <div class="col-xl">
                <form
                    @submit.prevent="send"
                    autocomplete="off"
                    autocapitalize="on"
                >
                    <div class="mb-3">
                        <label class="form-label" for="itemList"
                            >ITEM LIST
                        </label>
                        <input
                            type="hidden"
                            name="listIdP2h"
                            id="listIdP2h"
                            v-model="p2h.listIdP2h"
                        />
                        <input
                            type="text"
                            class="form-control"
                            id="itemList"
                            name="itemList"
                            v-model="p2h.itemList"
                            placeholder="Item List"
                            required="required"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="itemSub">ITEM </label>
                        <input
                            type="text"
                            class="form-control"
                            id="itemSub"
                            ref="itemSub"
                            name="itemSub"
                            v-model="p2h.itemSub"
                            placeholder="Item List"
                            required="required"
                        />
                    </div>
                    <div class="mb-3 border-bottom">
                        <label class="form-label" for="status">STATUS</label>
                        <span class="form-control-plaintext">
                            <div class="form-check form-switch mb-2">
                                <input
                                    @change="chgStatus"
                                    class="form-check-input"
                                    type="checkbox"
                                    id="status"
                                    v-model="chkStatus"
                                />
                                <label class="form-check-label" for="status"
                                    >Aktif</label
                                >
                            </div>
                        </span>
                    </div>
                    <button
                        type="submit"
                        class="btn btn-primary me-3 data-submit"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        class="btn btn-outline-danger"
                        data-bs-dismiss="offcanvas"
                    >
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    mounted() {
        this.initDatas();
    },
    data() {
        return {
            urlAction: "/",
            csrfTokens: "",
            p2h: {},
            eP2h: {},
            chkStatus: true,
            idP2hList: null,
            idP2hItem: null,
            halaman: 1,
        };
    },
    props: ["tokens", "url_action"],
    methods: {
        send() {
            this.idP2hItem != null
                ? this.updateAPI(this.idP2hItem)
                : this.save();
        },
        save() {
            axios.post("/sarana/masteritemp2h", this.p2h).then((response) => {
                var data = response.data;
                console.log(data);
                if (data.action) {
                    $(document).ready(function () {
                        $("#myToast").toast("show");
                        $("#dismisBtnItem").click();
                    });
                    this.initDatas();
                    this.updateData();
                }
            });
        },
        updateAPI(id) {
            axios
                .put("/sarana/masteritemp2h/" + id, this.p2h)
                .then((response) => {
                    var data = response.data;
                    if (data.action) {
                        $("#myToast").toast("show");
                        $("#dismisBtnItem").click();
                        this.initDatas();
                        this.updateData();
                    }
                });
        },
        updateData() {
            this.$emit("tabelP2hEmit");
        },
        focusItemInputs() {
            console.log("focusItemInputs focus");

            this.$refs.itemSub.focus();
        },

        chgStatus() {
            this.chkStatus != this.chkStatus;
            if (this.chkStatus) {
                this.p2h.statusSub = 1;
            } else {
                this.p2h.statusSub = 0;
            }
        },
        initDatas() {
            this.p2h = {};
            if (this.url_action != null) {
                this.urlAction = this.url_action;
            }
            if (this.tokens != null) {
                this.csrfTokens = this.tokens;
            }
            this.p2h.statusSub = 1;
        },
        setIdList(id, names) {
            this.initDatas();
            this.p2h.listIdP2h = id;
            this.p2h.itemList = names;
        },
        setEditIdList(id, names, c) {
            this.initDatas();
            this.p2h.listIdP2h = id;
            this.p2h.itemList = names;
            this.idP2hItem = c.idP2hItem;
            this.p2h.itemSub = c.itemSub;
            this.p2h.statusSub = c.statusSub;
        },
    },
    expose: ["initDatas", "focusItemInputs", "setIdList", "setEditIdList"],
};
</script>
